<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-row justify="center" align="center">
              <v-img
                src="@/assets/e-storage.png"
                max-width="150px"
                max-height="150px"
              />
              <span style="font-weight: 800; font-size: 36px" class="mt-2">
                e-Saraban
              </span>
            </v-row>
            <v-card class="elevation-12 mt-12">
              <v-toolbar color="#0074AA" style="color: white">
                <p class="font-weight-light mt-4" style="font-size: 20px">
                  ชุดแฟ้มเอกสาร
                </p>
              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="yearfrom"
                  v-model="yearfrom"
                  :lazy-validation="lazy"
                >
                  <span>เลือกปีของชุดเอกสาร</span>
                  <v-select
                    v-model="yearSelected"
                    :items="yearSelectedItems"
                    outlined
                    dense
                    :rules="Rules.empty"
                  ></v-select>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#0074AA"
                  style="color: white"
                  class="font-weight-light"
                  @click="submit()"
                  >ตกลง</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      userdata: "",
      lazy: "",
      yearfrom: "",
      Rules: {
        empty: [(v) => !!v || "กรุณาเลือกข้อมูล"],
      },
      yearSelected: "",
      yearSelectedItems: [
        "พ.ศ. 2564",
        "พ.ศ. 2565",
        "พ.ศ. 2566",
        "พ.ศ. 2567",
        "พ.ศ. 2568",
        "พ.ศ. 2569",
        "พ.ศ. 2570",
      ],
      NavigatorDefault: [
        {
          key: "menu0",
          text: "ทะเบียนหนังสือรับ",
          path: "/ManageForm1",
          name: "ManageForm1",
          icon: "download",
        },
        {
          key: "menu1",
          text: "ทะเบียนหนังสือส่ง",
          path: "/ManageForm2",
          name: "ManageForm2",
          icon: "upload",
        },
        {
          key: "menu2",
          text: "ทะเบียนประกาศ",
          path: "/ManageForm3",
          name: "ManageForm3",
          icon: "notification",
        },
        {
          key: "menu3",
          text: "ทะเบียนคำสั่ง",
          path: "/ManageForm4",
          name: "ManageForm4",
          icon: "audit",
        },
        {
          key: "menu4",
          text: "ทะเบียนบันทึกข้อความ",
          path: "/ManageForm5",
          name: "ManageForm5",
          icon: "profile",
        },
        {
          key: "menu5",
          text: "บัญชีหนังสือส่งเก็บ",
          path: "/ManageForm6",
          name: "ManageForm6",
          icon: "database",
        },
        {
          key: "menu6",
          text: "ทะเบียนหนังสือเก็บ",
          path: "/ManageForm7",
          name: "ManageForm7",
          icon: "hdd",
        },
        {
          key: "menu7",
          text: "บัญชีส่งมอบหนังสือครบ ๒๐ ปี",
          path: "ManageForm8",
          name: "ManageForm8",
          icon: "bars",
        },
        {
          key: "menu8",
          text: "บัญชีหนังสือครบ ๒๐ ปี ที่ขอเก็บเอง",
          path: "ManageForm9",
          name: "ManageForm9",
          icon: "bars",
        },
        {
          key: "menu9",
          text: "บัญชีฝากหนังสือ",
          path: "/ManageForm10",
          name: "ManageForm10",
          icon: "file-protect",
        },
        {
          key: "menu10",
          text: "บัญชีหนังสือขอทำลาย",
          path: "/ManageForm11",
          name: "ManageForm11",
          icon: "delete",
        },
        {
          key: "menu11",
          text: "จัดการผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
        {
          key: "menu22",
          text: "ผู้ใช้งาน",
          path: "/ManageUser",
          name: "ManageUser",
          icon: "user",
        },
      ],
    };
  },
  created() {
    this.year = JSON.parse(Decode.decode(localStorage.getItem("yearSelected")));
    this.userdata = JSON.parse(
      Decode.decode(localStorage.getItem("usersarabanbokaew"))
    );
    console.log(this.userdata, "userdata");
    // if (Object.prototype.hasOwnProperty.call(localStorage, "usersarabanbokaew")) {
    //   var checklogin = JSON.parse(
    //     Decode.decode(localStorage.getItem("usersarabanbokaew"))
    //   ).name;
    //   if (checklogin !== "") {
    //     this.$router.push("/ManageForm1");
    //   }
    // } else {
    //   console.log("ไม่มี user");
    //   this.$router.push("/");
    // }
  },
  methods: {
    resetValidation() {
      this.$refs.yearfrom.resetValidation();
    },
    async submit() {
      if (this.$refs.yearfrom.validate(true)) {
        localStorage.setItem("yearSelected", Encode.encode(this.yearSelected));
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          text: "เลือกปีสำเร็จ",
        });
        if (
          this.userdata.user.username == "admin" ||
          this.userdata.user.username == "admindonut"
        ) {
          await this.$router.push("/ManageForm1");
          console.log("admin");
        } else {
          const path = this.NavigatorDefault.filter(
            (Navigator) => Navigator.key === this.userdata.user.bookType
          );
          console.log(path);
          await this.$router.push(`${path[0].path}`);
          console.log("user");
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณาเลือกปีของชุดเอกสาร",
        });
      }
    },
    async login() {
      const login = {
        username: this.username,
        password: this.password,
      };

      if (this.username == "admin" && this.password == "1234") {
        localStorage.setItem("usersarabanbokaew", Encode.encode(login));
        await this.$router.push("/ManageForm1");
        // }
        // const response = await this.axios.post(
        //   `${process.env.VUE_APP_API}/users/adminLogin`,
        //   login
        // );
        // console.log("login", response.data.data);

        // if (response.data.response_status === "SUCCESS") {
        //   await this.$swal.fire({
        //     showConfirmButton: false,
        //     timer: 1000,
        //     timerProgressBar: true,
        //     icon: "success",
        //     text: "เข้าสู่ระบบเสร็จสิ้น",
        //   });

        // localStorage.setItem("usersarabanbokaew", Encode.encode(response.data.data));

        // await this.$router.push("/ManageProduct");
      } else if (this.username == "" || this.password == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอก username password",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: response.data.message,
        });
      }
    },
  },
};
</script>
